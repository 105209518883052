<template>
  <div id="app">
    <div class="step1" @click="stepFn" v-show="step === 1">
      <img src="@/assets/step1.png" />
      <div>左右滑动可以解锁任意视角</div>
      <div class="step1-next">我知道了</div>
    </div>
    <div class="step3" @click="stepFn" v-show="step === 2">
      <img src="@/assets/Play.png" class="step3-img1" />
      <div class="step3-img2">
        <img src="@/assets/step3.png" />
        <div>点击播放即可让记忆「活起来」</div>
        <div class="step2-next">我知道了</div>
      </div>
    </div>
    <div class="step4" @click="stepFn" v-show="step === 3">
      <div>点击「AR」在现实中召唤记忆</div>
      <div class="step2-next">我知道了</div>
      <img src="@/assets/step3.png" />
    </div>
    <playVideo :list="list" :datas="datas" :step="step" :pageData="pageData" @rule="rule" v-if="getList"
      @cutVol="cutVol" />

  </div>
</template>
<script>
import playVideo from '@/components/playVideo.vue'
export default {
  name: "",
  data() {
    return {
      list: [
        {
          volumetricVideo: 'https://holodata.prometh.xyz/FBXData/feitian8sec1024/'
        },
        {
          volumetricVideo: 'https://holodata.prometh.xyz/FBXData/baobaoheibai/'
        }
      ],
      datas: [],
      pageData: {
        token: '',
        guest: '',
        forTheFirstTime: '',
        currentId: ''
      },
      lastNum: '',
      getList: false,
      step: 4
    }
  },
  components: {
    playVideo,
  },
  async created() {
    const arr = window.location.search.split('&')
    arr.forEach((item, index) => {
      if (index === 0) {
        this.pageData.token = item.split('=')[1]
      }
      if (item.split('=')[0] === 'guest') {
        this.pageData.guest = item.split('=')[1]
      }
      if (item.split('=')[0] === 'id') {
        this.pageData.currentId = item.split('=')[1]
      }
      if (item.split('=')[0] === 'forTheFirstTime') {
        this.pageData.forTheFirstTime = item.split('=')[1]
        localStorage.setItem('forTheFirstTime', this.pageData.forTheFirstTime)
      }
    })
    console.log('this.pageData', this.pageData)
    /**
     * 测试链接
     */
    if (this.pageData.currentId == 'a301275c3695c2ac1261ada2a7ee22f8') {
      const data = {
        createdAt:1685686755591,
        id:"a301275c3695c2ac1261ada2a7ee22f8",
        number:"0000523368",
        phone:"18807738375",
        pictureUrl:"https://holodata.prometh.xyz/photo-studio/memoryCollection/0e92c63e360ddbca2f87a1d5f341ad8118580554452.png",
        shareImg:"https://holodata.prometh.xyz/photo-studio/memoryCollection/10fe0117105ed901633debfb9c91c4a8.png",
        transcribe:false,
        username:"1729e6301cbac814cc72d7c23837b0eb",
        volumetricVideo:"https://holodata.s3.cn-northwest-1.amazonaws.com.cn/FBXData/hsyp4/"
      }
      this.datas = data
      this.getList = true
      // console.log('this.cccccccccc', this.datas, this.getList)
      return;
    }
    if (this.pageData.guest === 'true') {
      try {
        const response = await fetch('https://holodata.prometh.xyz/miniSpirituality/guestFilmFavorites.json')
        const { data } = await response.json()
        this.list = data
        data.forEach(item => {
          if (item.id === this.pageData.currentId) {
            this.datas = item
            this.getList = true
          }
        })
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        const response = await fetch('https://cn-northwest-1-memorycollection.prometh.xyz/materials', {
          headers: {
            'pm-token': this.pageData.token
          }
        })
        const { data } = await response.json()
        this.list = data

        data.forEach(item => {
          if (item.id === this.pageData.currentId) {
            this.datas = item
            this.getList = true
          }
        })
      } catch (err) {
        console.log(err)
      }
    }
  },
  mounted() { },
  methods: {
    //切换体积视频
    cutVol(id) {
      this.pageData.currentId = id
      this.getList = false
      this.list.forEach(item => {
        if (item.id === this.pageData.currentId) {
          this.datas = item
          // this.getList = true
        }
      })
      setInterval(() => {
        this.getList = true
      }, 1)
    },

    rule(first) {
      if (this.step === 4 || first) {
        this.step = 1
      } else {
        this.step = 4
      }
    },
    getWinHeight() {
      return `height:${document.body.offsetHeight}px`
    },
    stepFn() {
      switch (this.step) {
        case 1:
          this.step = 2
          break;
        // case 2:
        //   this.step = 3
        // break;
        case 2:
          this.step = 3
          break;
        case 3:
          this.showPlayBtn = true
          // this.explainShow = false
          this.step = 4
      }
    },

  },
}
</script>
<style scoped>
#app {
  background: url('~@/assets/bg.png') no-repeat center;
  background-size: 100vw 100vh;
  color: #fff;
  box-sizing: border-box;
}

.step1,
.step2 {
  z-index: 1;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 14px;
}

.step3 {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 14px;
}

.step1-next,
.step2-next {
  width: 30.33vw;
  height: 5vh;
  background-color: #8861ff;
  border-radius: 2.5vh;
  line-height: 5vh;
  font-size: 14px;
  margin: 2vh auto 0;
}

.step4 {
  z-index: 1;
  width: 70vw;
  position: absolute;
  top: 80vh;
  left: 40vw;
  text-align: center;
  font-size: 14px;
}

.step3-img2 {
  position: absolute;
  top: 40%;
}

.step3-img1 {
  transform: translateX(6vw)
}
</style>

