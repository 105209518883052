import * as THREE from "three"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
class mesh4D {
  constructor(scene,camera,showPlayBtn) {
    this.root = new THREE.Object3D()
    this.glb_fns = ''
    this.mp4_fns = ''
    this.glb_nums = 0
    this.videos = []
    this.data_url = ''
    this.gltf = []
    this.progress = 0
    this.sumLength = 60 // 总长度
    this.paragraphLength = 0 // 每段长度
    this.glb_loaders = new GLTFLoader()
    this.dracoLoader = new DRACOLoader()
    this.currentGltf = 0
    this.clock = new THREE.Clock()
    this.count = 0
    this.mp3 = {}
    this.scene = scene
    this.camera = camera
    this.showPlayBtn = showPlayBtn
  }
  async load (data_url,onload) {
    this.dracoLoader.setDecoderPath( 'https://holodata.prometh.xyz/draco/' )
    this.glb_loaders.setDRACOLoader( this.dracoLoader )
    this.data_url = data_url
    try {
      const response = await fetch(data_url+'glb_info.json')
      const json = await response.json()
      this.glb_fns = json.glb_fns
      this.mp4_fns = json.mp4_fns
      const bol = json.mp3_fns.indexOf('/')
      if (bol == -1) {
        this.mp3 = await this.audioLoading(data_url + json.mp3_fns)
      } else {
        const arr = json.mp3_fns.split('/')
        const volumeVideoName = arr[arr.length-1]
        this.mp3 = await this.audioLoading(data_url + volumeVideoName)
      }
      this.glb_nums = this.glb_fns.length
      let num = this.sumLength / this.glb_nums
      num = Math.ceil(num*10)
      this.paragraphLength = (num*1.0/10)*2
      this.GLTFloads(onload)
    } catch (err) {
      console.log(err)
    }
  }
  GLTFloads (onload) {
    for (let i = 0; i<this.glb_nums; i++) {
      this.progress++
      this.GLTFRequest(this.glb_fns[i],i,onload)
    }
  }
  async GLTFRequest (url,i,onload) { 
    this.gltf[i] = {} 
    try {
      const mp4texture = await this.MP4Request(i)
      this.glb_loaders.load( this.data_url + url,obj=>{
        
        this.gltf[i].gltfMash = obj.scene
        obj.scene.position.set(0,-.92,0)
        for (let track in obj.animations[0].tracks) {
          obj.animations[0].tracks[track].setInterpolation(THREE.InterpolateDiscrete)
        }
        this.gltf[i].mixer = new THREE.AnimationMixer(obj.scene)
        this.gltf[i].animActions = this.gltf[i].mixer.clipAction(obj.animations[0])
        this.gltf[i].animActions.clampWhenFinished = true
        this.gltf[i].animActions.loop = THREE.LoopOnce
        if (i == 0) {
          this.gltf[0].animActions.play()
          this.gltf[0].animActions.paused = true
        }
        this.gltf[i].gltfMash.traverse( child => {
          if (child.isMesh) {
            child.frustumCulled = false
            child.material =new THREE.MeshBasicMaterial({map: mp4texture, side:THREE.FrontSide, depthTest:true, depthWrite: true, transparent:false})
            child.material.map.encoding = 3001
          }
        })
        this.gltf[i].mixer.addEventListener('finished',()=>{
          this.clock.elapsedTime = 0
          if (this.currentGltf+1 === this.glb_nums) {
            this.root.remove(this.gltf[this.currentGltf].gltfMash)
            this.gltf[this.currentGltf].gltfMash.traverse( (v) => {
              if (v.type === 'Mesh') {
                v.geometry.dispose()
                v.texture.dispose()
                v.material.dispose()
              }
            })
            this.gltf[this.currentGltf].animActions.reset()
            this.currentGltf = 0
            this.mp3.stop()
            this.pause()
            this.root.add(this.gltf[this.currentGltf].gltfMash)
          } else {
            setTimeout(()=>{
              if (!this.root) return 
              this.root.remove(this.gltf[this.currentGltf].gltfMash)
              this.gltf[this.currentGltf].gltfMash.traverse( (v) => {
                if (v.type === 'Mesh') {
                  v.geometry.dispose()
                  v.texture.dispose()
                  v.material.dispose()
                }
              })
              this.gltf[this.currentGltf].animActions.reset()
              this.currentGltf++
              this.gltf[this.currentGltf].animActions.play()
              this.gltf[this.currentGltf].mixer.update(0.001)
              this.root.add(this.gltf[this.currentGltf].gltfMash)
              this.videos[this.currentGltf].play()
            },50)
          }
        })
        this.count++
        this.progress = this.progress + this.paragraphLength
        if (this.count === this.glb_nums) {
          this.progress = 100
          this.root.add(this.gltf[0].gltfMash)
          onload(this.root)
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
  MP4Request(i) {
    return new Promise( (resolve,reject) =>{
      this.videos[i] = document.createElement('video')
      this.videos[i].src = this.data_url + this.mp4_fns[i]
      this.videos[i].setAttribute('webkit-playsinline','true')
      this.videos[i].setAttribute('playsinline','true')
      this.videos[i].crossOrigin = "anonymous" //域
      let videoTexture = new THREE.VideoTexture(this.videos[i])
      videoTexture.flipY = false
      window.WeixinJSBridge.invoke('getNetworkType', {}, () => {
        this.videos[i].load()
        this.videos[i].currentTime = 0.001
      })
      this.videos[i].addEventListener('canplaythrough',()=>{
        resolve(videoTexture)
      })
      // this.videos[i].addEventListener('ended',e=>{
      //   e.composedPath()[0].currentTime = 0
      // })
    })
  }
  audioLoading(url) {
    const audioListener = new THREE.AudioListener()
    const oceanAmbientSound = new THREE.Audio( audioListener )
    this.scene.add( oceanAmbientSound )
    this.camera.add( audioListener )
    const loader = new THREE.AudioLoader()
    return new Promise((resolve)=>{
      loader.load(url,( audioBuffer ) => {
        oceanAmbientSound.setBuffer( audioBuffer )
        resolve(oceanAmbientSound)
      })
    })
  }
  pause() {
    this.videos[this.currentGltf].pause()
    this.gltf[this.currentGltf].animActions.paused = true
    this.mp3.pause()
    this.showPlayBtn.watch = true
  }
  play () {
    this.gltf[this.currentGltf].animActions.paused = false
    this.gltf[this.currentGltf].animActions.play()
    this.videos[this.currentGltf].play()
    this.mp3.play()
  }
  delete() {
    this.root = null
    this.videos = null
    this.gltf = null
    this.glb_loaders = null
    this.dracoLoader = null
    this.clock = null
    this.mp3 = null
    this.scene = null
    this.camera = null
  }
  update() {
    if (this.gltf.length>1&&this.gltf[this.currentGltf].mixer !== undefined) {
      this.gltf[this.currentGltf].mixer.update(this.clock.getDelta())
    }
  }
}
export {mesh4D}