<template>
  <div class="m-progress" :style="`width:${width}vw;`">
     <div class="refreshPage" v-if="refresh" @click="refreshPageFn">刷新页面</div>
    <div class="m-progress-outer">
      <div class="loading" v-if="timeout">正在加载中<div class="dot">...</div></div>
      <div class="loading" style="fontSize:20px;" v-else>请求超时,建议刷新页面</div>
      <div class="m-progress-inner">
          <div class="u-progress-bg" :style="`width: ${progress >= 100 ? 100:progress}%; height: ${strokeWidth}vw;`">
            <p class="u-progress-text" :style="`height: ${strokeWidth}vw;line-height:${strokeWidth}vw`">{{ progressInt>=100 ?    100:progressInt }}%</p>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Progress',
  data() {
    return {
      timer: null,
      refresh: false,
      timeout: true
    }
  },
  computed: {
    progressInt() {
      return parseInt(this.progress)
    }
  },
  mounted(){
    setTimeout(()=> {
      if (this.progress == 0) {
        this.timeout = false
        this.refresh = true
      }
    },15000)
  },
  watch:{
    progress(newv,oldv){
      this.timeout = true
      this.refresh = false
      clearInterval(this.timer)
      this.timer = null
      this.timer = setInterval(() => {
        if (this.progress === 100) {
          clearInterval(this.timer)
          this.timer = null
        } else {
          clearInterval(this.timer)
          this.timer = null
          this.timeout = false
          this.refresh = true
        }
      }, 10000)
    }
  },
  props: {
    width: { // 进度条总宽度
      type: Number,
      default: 60
    },
    progress: { // 当前进度
      type: Number,
      default: 0
    },
    strokeWidth: { // 进度条线的宽度
      type: Number,
      default: 7
    }
    // showInfo: { // 是否显示进度数值或状态图标
    //   type: Boolean,
    //   default: false

    // }
  },
  methods: {
    refreshPageFn() {
      location.reload();
    }
  },
}
</script>
<style lang="less" scoped>
.m-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  height: 24px;
  margin: 0 auto;
  display: flex;
  .refreshPage {
    position: absolute;
    left: 50%;
    top: 10vh;
    transform: translateX(-50%);
    background: url('~@/assets/bottomBtn.png') no-repeat center;
    width: 41.33vw;
    height: 7.39vh;
    background-size: 100% 100%;
    color: #fff;
    text-align: center;
    line-height: 7.39vh;
  }
  .m-progress-outer {
    width: 100%;
    .loading {
      font-weight: 700;
      display: flex;
      justify-content: center;
      margin-bottom: 2.5vh;
      font-size: 22px;
      text-shadow: 1px 1px 1px rgba(0,0,0,.7);
      // -webkit-text-stroke: .5px #e0b2e6;
      .dot{
        	/*让点垂直居中*/
            height: 1em;
            line-height: 1;
            /*让点垂直排列*/
            display: flex;
            flex-direction: column;
            /*溢出部分的点隐藏*/
            overflow: hidden;
        }
        .dot::before{
        	/*三行三种点，需要搭配white-space:pre使用才能识别\A字符*/
            content: '...\A..\A.';
            white-space: pre-wrap;
            animation: dot 1s infinite step-end;/*step-end确保一次动画结束后直接跳到下一帧而没有过渡*/
        }
        @keyframes dot{
            33% {transform: translateY(-2em);}
            66% {transform: translateY(-1em);}
        }
    }
    .m-progress-inner {
      display: inline-block;
      width: 100%;
      background: linear-gradient(to right, #ffa6d3, #d7b8ff);
      border-radius: 100px;
      .u-progress-bg {
        position:relative;
        // background: #1890FF;
        background: linear-gradient(to right, #e76fac, #b07ef1);
        border-radius: 100px;
        transition: all .3s cubic-bezier(.08,.82,.17,1);
      }
      .u-success-bg {
        background: #52C41A;
      }
    }
  }
  .u-success {
    width: 16px;
    height: 16px;
    fill: #52C41A;
    margin-left: 8px;
    margin-top: 4px;
  }
  .u-progress-text {
    position: absolute;
    top:0;
    right: 0;
    margin: 0;
    font-size: 12px;
  }
}
</style>