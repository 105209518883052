<template>
  <div class='playVideo' ref="playVideo">
    <div class="rule" @click.stop="rule" v-if="showBtn">指引</div>
    <div @click="showPopup" class="showPopupBtn" v-if="showBtn"/>
    <div @click.stop="volPlayFn" class="playButton" v-if="showPlayBtn.watch && step === 4"/>
    <div v-if="showBtn">
      <div class="bottomBtn">
        <div @click.stop="gotoShare" class="gotoArShare">分享</div>  
        <div @click.stop="gotoAR" class="gotoArbtn">AR</div>
      </div>
    </div>
    <Progress :width="60" :progress="currentProgress" v-if="progressShow"/>
    <popup 
      :list="list" 
      :currentId="pageData.currentId" 
      @showPopup="showPopup" 
      @cutVol="cutVol" 
      v-if="popupShow"
    />
    <!-- <Progress :width="50" :progress="currentProgress"/> -->
  </div>
</template>
<script>
import * as THREE from "three"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import Progress from '@/components/progress.vue'
import popup from '@/components/popup.vue'
// import { getTime } from '@/utils'
let mesh4D = {}
if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
  mesh4D = require('@/mesh4D-ios.js').mesh4D
} else if (/(Android)/i.test(navigator.userAgent)) {
  mesh4D = require('@/mesh4D-android.js').mesh4D
} else {
  mesh4D = require('@/mesh4D-ios.js').mesh4D
}
export default {
  name: "playVideo",
  data() {
    return {
      timer:null,
      popupShow: false,//弹出层
      showBtn: false,
      showPlayBtn: {
        watch:false
      },
      currentProgress: 0,
      progressShow: true,
      pmremGenerator: null,
      gltfLoader: new GLTFLoader(),
      draco: new DRACOLoader(),
      rotate: true,
      // loadingFont: '正在加载中请稍后...',
      createdTime: '2023 01 01',
      clock: new THREE.Clock(),
      mixer:null,
      animActions: null
    }
  },
  props:{
    datas:{
      type: Object,
      required: true
    },
    pageData: {
      type: Object,
      required: true
    },
    list:{
      type: Array,
      required: true
    },
    step: {
      type:Number,
      required: true
    }
  },
  components: {
    Progress,
    popup

  },
  created() {},
  mounted() {
    this.renderer= null
    this.scene= null
    this.camera= null
    this.controls= null
    this.mesh = null
    this.volPlay = null
    this.draco.setDecoderPath( 'https://holodata.prometh.xyz/draco/' )
    this.gltfLoader.setDRACOLoader( this.draco )
    this.initScene()
  },
  methods: {
    //切换体积视频
    cutVol(id) {
      this.$emit('cutVol',id)
    },

    rule () {
      this.pauseMp4()
      this.$emit('rule')
    },
    gotoAR () {
      if ( this.step === 4 && this.currentProgress === 100) {
        wx.miniProgram.redirectTo({
          url: `/pages/arWebView/index?url=https://holodata.s3.cn-northwest-1.amazonaws.com.cn/holographicAr/index.html&volumetricVideo=${this.datas.volumetricVideo}&shareImg=${this.datas.shareImg}&number=${this.datas.number}&transcribe=${this.datas.transcribe}`
        })
      }
    },
    gotoShare() {
      if ( this.step === 4 && this.currentProgress === 100) {
        this.pauseMp4()
        wx.miniProgram.navigateTo({
          url: `/pages/sharePoster/index?url=https://holodata.s3.cn-northwest-1.amazonaws.com.cn/holographicAr/index.html&volumetricVideo=${this.datas.volumetricVideo}&shareImg=${this.datas.shareImg}&number=${this.datas.number}&transcribe=${this.datas.transcribe}`
        })
      }
    },
    volPlayFn() {
      this.mesh.play()
      this.showPlayBtn.watch = false
      if (this.rotate) {
        let timer = setInterval(()=>{
          if (this.volPlay.rotation.y < -6) {
            this.volPlay.rotation.y = this.volPlay.rotation.y % -6
          }
          if ( this.volPlay.rotation.y == 0) {
              clearInterval(timer)
              timer = null
          }
          if (this.volPlay.rotation.y > -3) {
            this.volPlay.rotation.y += 0.1
            if (this.volPlay.rotation.y > -0.2) {
              this.volPlay.rotation.y = 0
            }
          } else {
            this.volPlay.rotation.y -= 0.1
            if (this.volPlay.rotation.y < -5.8) {
              this.volPlay.rotation.y = 0
            }
          }
        }, 20)
        this.rotate = false
      }
    },
    showPopup() {
      this.popupShow = !this.popupShow
    },
    initMp4(){
      this.mesh = new mesh4D(this.scene,this.camera,this.showPlayBtn)
      this.getProgress()
      this.mesh.load(this.datas.volumetricVideo,mp4=>{
        this.animActions.play()
        this.volPlay = mp4
        if (this.datas.kid) {
          this.mesh.root.position.set(0,-.55,0)
          this.mesh.root.scale.set(2,2,2)
        }
        this.scene.add(this.volPlay)
        this.showPlayBtn.watch = true
        this.progressShow = false
        // this.loadingFont = ''
        const first = localStorage.getItem('forTheFirstTime')
        if (first !== 'true') {
          this.rule(1)
        }
      })
    },
    pauseMp4() {
      this.mesh.pause()
      this.showPlayBtn.watch = true
    },
    getProgress() {
      const timer = setInterval(()=>{
          this.currentProgress = this.mesh.progress
        if (this.mesh.progress>=100) {
          this.currentProgress = 100
          clearInterval(timer)
        }
      },50)
    },
    initScene(){
      this.scene = new THREE.Scene()
      this.camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 )
      this.renderer = new THREE.WebGLRenderer({ antialias: true })
      this.renderer.setSize( window.innerWidth, window.innerHeight )
      this.renderer.outputEncoding = THREE.sRGBEncoding
      this.renderer.setPixelRatio(1.5)
      this.camera.add( new THREE.AmbientLight( 0xffffff ))
      this.$refs.playVideo.appendChild( this.renderer.domElement )
      this.camera.position.set(0, 2.2, 2) //设置相机位置
      this.pmremGenerator = new THREE.PMREMGenerator( this.renderer )
      this.pmremGenerator.compileEquirectangularShader()
      this.neutralEnvironment = this.pmremGenerator.fromScene( new RoomEnvironment() ).texture
      this.getCubeMapTexture().then(( { envMap } ) => {
        this.scene.environment = envMap
        this.scene.background = envMap
      })
      this.controls = new OrbitControls( this.camera,this.renderer.domElement )
      this.controls.minPolarAngle = Math.PI/3
      this.controls.maxPolarAngle = Math.PI/2.0
      this.controls.enableZoom = false
      this.controls.enablePan = false
      this.gltfLoader.load( 'https://holodata.prometh.xyz/AllModel/qixidrc.glb',obj=>{
        this.mixer = new THREE.AnimationMixer(obj.scene)
        this.animActions = this.mixer.clipAction(obj.animations[0])
        obj.scene.scale.set(2,2,2)
        obj.scene.position.set(0,-.77,0)
        this.scene.add(obj.scene)
        this.animate()
        this.showBtn = true
        this.initMp4()
      })
    },
    getCubeMapTexture() {
      return new Promise( ( resolve, reject ) => {
      new RGBELoader()
        .load( 'https://holodata.prometh.xyz/AllModel/huanjingLight.hdr', ( texture ) => {
          const envMap = this.pmremGenerator.fromEquirectangular( texture ).texture
          this.pmremGenerator.dispose()
          resolve( { envMap } )
        }, undefined, reject )
      })
    },
    animate(){      
      // this.timer = setInterval(()=>{
      this.renderer.render( this.scene, this.camera )
      if (this.mesh) {
        this.mesh.update()
        if (this.volPlay && this.rotate) {
          this.volPlay.rotation.y -= 0.013
        }
        if (this.mixer) {
          this.mixer.update(this.clock.getDelta())
        }
      }
      this.controls.update()
      requestAnimationFrame(this.animate)
      // },20)
    },
  },
  beforeDestroy() {
    cancelAnimationFrame(this.timer)
    this.timer = null
    if (this.scene) {
      this.scene.traverse( (v) => {
        if (v.type === 'Mesh') {
          v.geometry.dispose()
          v.material.dispose()
        }
      })
      while (this.scene.children.length > 0) {
        this.scene.remove(this.scene.children[0])
      }
      this.scene.clear()
      this.renderer.dispose()
      this.renderer.forceContextLoss()
      this.renderer.domElement = null
      this.renderer = null
      this.scene = null
      this.camera = null
      this.controls = null
    }
  },
}
</script>
<style scoped>
.playVideo {
  color: #fff;
}
.showPopupBtn {
  position: absolute;
  right: 3.5%;
  top: 10%;
  width: 12.8vw;
  height: 7.76vh;
  background: url('~@/assets/cutMemory.png') no-repeat center;
  background-size: contain;
  font-size: 12px;
}

/* .loadingFont {
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%,-50%)
} */
.rule {
  position: absolute;
  right: 5%;
  top: 3%;
  width: 9.6vw;
  height: 9.6vw;
  text-align: center;
  font-size: 12px;
  line-height: 9.6vw;
  background-color: rgba(181, 105, 214, 0.3);
  border-radius: 50%;
}
.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: url('~@/assets/Play.png') no-repeat center;
  width: 18.93vw;
  height: 18.93vw;
  border-radius: 50%;
  background-size: 100% 100%;
}
.bottomBtn {
  width: 100vw;
  position: absolute;
  bottom: 3.9vh;
  left: 0;
  display: flex;
  justify-content: space-around;
}
.createdTime {
  pointer-events: none;
  width: 82.4vw;
  height: 3.94vh;
  background-color: rgba(181, 105, 214, 0.3);
  position: absolute;
  bottom: 12.67vh;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  line-height: 3.94vh;
  border-radius: 1.97vh;
  font-size: 12px
}
.gotoArbtn,.gotoArShare {
  background: url('~@/assets/bottomBtn.png') no-repeat center;
  width: 41.33vw;
  height: 7.39vh;
  background-size: 100% 100%;
  color: #fff;
  text-align: center;
  line-height: 7.39vh;
}
.explain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  opacity: .8;
  background-color: #0F032B;
}
</style>