<template>
  <div class='pupop'>
    <div class="cantainer">
      <div class="top">
        <img src="@/assets/close.png" alt="" class="close" width="20" @click="showPopup">
        <div class="title">全部记忆</div>
      </div>
      <div class="list">
        <div class="listChild" v-for="key in list" :key="key.id" @click="cut(key.id)">
          <div class="maskLayer" v-if="key.id === currentId">
            <img src="@/assets/group.png" alt="">
          </div>
          <img :src="key.pictureUrl" alt="" class="childImg">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "pupop",
  data() {
    return {}
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    currentId: {
      type: String,
      required: true 
    }
  },
  components: {},
  created() {},
  mounted() {
    console.log(this.currentId);
    
  },
  methods: {
    cut(id) {
      this.$emit('cutVol',id)
    },
    showPopup() {
      this.$emit('showPopup')
    }
  },
}
</script>
<style scoped>
.pupop {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000055;
  z-index: 1000;
}
.cantainer {
  background-color: #fff;
  width: 100%;
  height: 56.4vh;
  position: absolute;
  left: 0;
  bottom: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: rgba(47, 30, 94,.95);
  padding: 3vh 5vw;
  box-sizing: border-box; 
}
.top {
  width: 90vw;
  margin-bottom: 4.15vh; 
}
.close {
  vertical-align: middle;
}
.title {
  position: absolute;
  top: 3vh;
  left: 50%;
  font-size: 20px;
  transform: translateX(-50%);
}
.list {
  display: flex;
  /* justify-content: space-around; */
  flex-wrap: wrap;
}
.listChild {
  position: relative;
  width: 29.4vw;
  height: 19.24vh;
  margin: .5vh .2vw;
  padding-top: 2vh;
  background: url('~@/assets/listChild.png') no-repeat center;
  background-size: contain;
  box-sizing: border-box;
}
.childImg {
  width: 100%;
  height: 76%;
  object-fit: contain;
}
.maskLayer {
  width: 29.4vw;
  height: 19.24vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(47, 30, 94,.75);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>